<template>
  <CaixaquiMenu :items="menus"></CaixaquiMenu>
</template>

<script>
import CaixaquiMenu from "../../../components/caixaqui/CaixaquiMenu";
import ROUTERS from "../../../routes/router-constants";
import { mapGetters } from "vuex";

export default {
  name: "CaixaquiMenuServicoCliente",
  components: {
    CaixaquiMenu
  },
  computed: {
    ...mapGetters("controller", ["sourceRoute"]),
    menus() {
      return [
        { text: "Negócios", to: { name: ROUTERS.caixaqui.menus.negocios.name } },
        { text: "Voltar ao Menu Inicial", to: { name: ROUTERS.caixaqui.menus.principal.name } },
        { text: "Sair", to: this.sourceRoute, isOrange: true }
      ];
    }
  }
};
</script>

<style scoped></style>
